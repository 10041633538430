import React, { Component } from "react"
import PropTypes from "prop-types"
import HeaderSelectItem from "./HeaderSelectItem"
import "./HeaderSelect.css"

class HeaderSelect extends Component {
  render() {
    let options = this.props.options.map((option, idx) => (
      <HeaderSelectItem key={idx} name={option.name} value={option.value} selected={option.selected} />
    ))

    return (
      <div className="header-select">
        <div className="header-select-background" />
        <div className="header-select-container">
          <select name={this.props.name} value={this.props.value} onChange={this.props.onChange}>
            {options}
          </select>
        </div>
        <p className="header-select-info">{this.props.info}</p>
      </div>
    )
  }
}

HeaderSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  info: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default HeaderSelect
