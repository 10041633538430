import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import Header from "./components/Header"
import ErrorItem from "./components/ErrorItem"
import "../../node_modules/font-awesome/css/font-awesome.min.css"
import "./Auth.css"

class Auth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      errors: [],
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
    this.handleLoginCallback = this.handleLoginCallback.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleLoginCallback(success, errorMsgs) {
    console.log("Auth.handleLoginCallback")
    if (!success) {
      this.setState(() => ({
        errors: errorMsgs,
      }))
    }
  }

  handleLoginSubmit(event) {
    console.log("Auth.handleLoginSubmit")
    this.props.authenticate(this.state.email, this.state.password, this.handleLoginCallback)
    event.preventDefault()
  }

  render() {
    const from = sessionStorage.getItem("referrer") || "/"
    if (this.props.isAuthenticated === true) {
      return <Redirect to={{ pathname: from, search: this.props.location.search }} />
    }

    let errorItems
    if (this.state.errors) {
      errorItems = this.state.errors.map(function(c, idx) {
        return <ErrorItem error={c} />
      })
    }

    return (
      <div className="login">
        <div className="login-header">
          <Header />
        </div>
        <form className="login-form" onSubmit={this.handleLoginSubmit}>
          {errorItems}
          <div className="form-group">
            <input
              name="email"
              type="textfield"
              placeholder="Email Address"
              value={this.state.username}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              name="password"
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <input type="submit" value="Login" />
          </div>
        </form>
      </div>
    )
  }
}

export default Auth
