import React, { Component } from "react"
import PropTypes from "prop-types"

class HeaderSelectItem extends Component {
  render() {
    return <option value={this.props.value}>{this.props.name}</option>
  }
}

HeaderSelectItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default HeaderSelectItem
