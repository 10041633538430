import React, { Component } from "react"
import PropTypes from "prop-types"

import CircleButton from "./CircleButton"
import FormInput from "./FormInput"
import FormSelect from "./FormSelect"

class ContactFormNumber extends Component {
  constructor(props) {
    super(props)
    this.state = {
      f_number: this.props.number,
      f_type: this.props.type,
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    // update callback
    let f_number = this.state.f_number
    let f_type = this.state.f_type
    if (name === "f_number") {
      f_number = value
    } else if (name === "f_type") {
      f_type = value
    }
    this.props.handleNumberChange(f_number, f_type)

    // update our state
    this.setState({
      [name]: value,
    })
  }

  delete = event => {
    console.log("delete")
    this.props.handleNumberDelete()
  }

  render() {
    let numberOptions = [
      { name: "Mobile", value: "Mobile" },
      { name: "Work", value: "Work" },
      { name: "Other", value: "Other" },
    ]

    return (
      <div className={"contact-form-number " + this.props.className}>
        <div className="row">
          <div className="col-md-8 contact-form-number-number">
            <FormInput
              name="f_number"
              type="tel"
              pattern="^\d{10}$"
              info="Number"
              value={this.state.f_number}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="col-md-3 contact-form-number-type">
            <FormSelect
              name="f_type"
              info="Type"
              value={this.state.f_type}
              options={numberOptions}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="col-md-1 contact-form-number-type">
            <CircleButton
              className="btn-small float-right red"
              iconClassName="fa-trash"
              text=""
              onClick={this.delete}
            />
          </div>
        </div>
      </div>
    )
  }
}

ContactFormNumber.propTypes = {
  className: PropTypes.string,
  handleNumberChange: PropTypes.func.isRequired,
  handleNumberDelete: PropTypes.func.isRequired,
  number: PropTypes.string,
  type: PropTypes.string,
}

export default ContactFormNumber
