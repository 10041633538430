import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Foreman from "../libraries/Foreman"
import Popup from "reactjs-popup"

import HeaderButton from "./HeaderButton"
import "./MessageHeader.css"

class MessageHeader extends Component {
  constructor(props) {
    super(props)
    this.foreman = Foreman.getInstance()
  }

  addContact = event => {
    console.log(this.props)
    let numberParts = this.props.conversation.displayName.split("-")
    let number = numberParts[numberParts.length - 1]
    this.props.history.push({
      pathname: `/contacts/new`,
      state: {
        f_directory: this.props.room.directory,
        f_name: "",
        f_numbers: [{ number: number, type: "Mobile" }],
      },
    })
  }

  toggleReadStatus = event => {
    if (this.props.conversation.unread) {
      this.foreman.mark_conversation_read(this.props.conversation.uuid, () => {})
    } else {
      this.foreman.mark_conversation_unread(this.props.conversation.uuid, () => {})
    }
  }

  viewContact = event => {
    console.log("viewContact btn")
    console.log(this.props)
    this.props.history.push({
      pathname: `/contacts/${this.props.room.directory}/${this.props.conversation.contact.uuid}`,
    })
  }

  handleArchive = event => {
    this.foreman.archive_conversation(this.props.conversation.uuid, () => {})
  }

  isConversationActive(conversation) {
    return conversation.state === "Active"
  }

  render() {
    // sms
    let converstaionType = ""
    let displayName = ""
    let contactButton = null
    let markButton = null
    let archivePopup = null
    if (this.props.hasOwnProperty("conversation") && this.props.conversation) {
      const isActive = this.isConversationActive(this.props.conversation)
      converstaionType = (
        <span className="type">
          <i className="fa fa-mobile fa-2x" />
        </span>
      )
      if (this.props.conversation.contact) {
        contactButton = (
          <HeaderButton text="Info" icon="fa-info" className="btn-small btn-white" onClick={this.viewContact} />
        )
      } else {
        contactButton = (
          <HeaderButton text="Add Contact" icon="fa-plus" className="btn-small btn-white" onClick={this.addContact} />
        )
      }
      if (this.props.room && this.props.room.type === "BroadcastGroup" && isActive) {
        let markText = "Mark as " + (this.props.conversation.unread ? "Read" : "Unread")
        markButton = <HeaderButton text={markText} className="btn-small btn-white" onClick={this.toggleReadStatus} />
      }
      if (isActive) {
        const archiveButton = <HeaderButton text="Archive" className="btn-small btn-white" />
        archivePopup = (
          <Popup trigger={archiveButton} modal closeOnDocumentClick>
            {close => (
              <div className="modal">
                <div className="modal-header">
                  Archive Conversation?
                  <i className="fa fa-times" onClick={close} />
                </div>
                <div className="modal-content">
                  <p>
                    Are you sure? Once a conversation is archived, it cannot be unarchived. Any new communication with
                    the recipient willl result in a new conversation
                  </p>
                </div>
                <div className="modal-confirm">
                  <HeaderButton
                    text="Yes, archive this conversation"
                    className="btn-small btn-white"
                    onClick={this.handleArchive}
                  />
                </div>
              </div>
            )}
          </Popup>
        )
      }

      let displayNameIsArchived = null
      if (!isActive) {
        displayNameIsArchived = <span className="archived">(Archived)</span>
      }
      displayName = (
        <span className="contact-name">
          {this.props.conversation.displayName} {displayNameIsArchived}
        </span>
      )
    }

    return (
      <div className="header">
        <i
          className="menu fa fa-bars"
          onClick={() => {
            this.props.slideout.toggle()
          }}
        />
        {converstaionType}
        {displayName}
        <div className="header-btns">
          <div className="header-fill" />
          {contactButton}
          {markButton}
          {archivePopup}
        </div>
      </div>
    )
  }
}

MessageHeader.propTypes = {
  room: PropTypes.object,
  conversation: PropTypes.object,
}

export default withRouter(MessageHeader)
