import React, { Component } from "react"
import PropTypes from "prop-types"
import "./MediaItem.css"

class MediaItem extends Component {
  handleImageError = event => {
    event.target.src = "/images/media-not-available.png"
    event.target.alt = "Content no longer available"
  }
  render() {
    return (
      <div className={`media-box media-box-${this.props.direction}`}>
        <img src={this.props.url} alt="Message Attachment" onError={this.handleImageError} />
      </div>
    )
  }
}

MediaItem.propTypes = {
  direction: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default MediaItem
