import "../../../node_modules/font-awesome/css/font-awesome.min.css"
import "../../auth/Auth.css"

import React, { Component } from "react"

import Header from "../../auth/components/Header"
import Foreman from "../libraries/Foreman"

class LinkWebex extends Component {
  constructor(props) {
    super(props)
    this.foreman = Foreman.getInstance()
  }
  componentDidMount() {
    this.foreman.get_current_user(user => {
      console.log("USER", user)
      const webexLink = `https://webexapis.com/v1/authorize?client_id=Caa2d9f6bcb213359aed1174a122f34db8fc3edc5821b5453b6bc27ff7f8d064b&response_type=code&redirect_uri=https%3A%2F%2Fcrew.301solutions.com%2Fwebex-user&scope=spark%3Akms%20spark%3Apeople_read%20spark%3Amessages_read&state=${
        user.uuid
      }`
      window.open(webexLink, "_self")
    })
  }
  render() {
    return (
      <div className="login">
        <div className="login-header">
          <Header />
        </div>
        <div className="login-form">
          <p>You will be redirected to Webex shortly</p>
        </div>
      </div>
    )
  }
}

export default LinkWebex
