import io from "socket.io-client"
import Foreman from "./Foreman"

export default class WebSocketManager {
  static instance = null
  foreman = Foreman.getInstance()
  _socket = null
  _connected = false
  _authorized = false
  _rooms = new Set()

  constructor() {
    let FLAG_NOTIFY = localStorage.getItem("notify")
    let ws_path = process.env.REACT_APP_FOREMAN_NOTIFY_PATH
    if (FLAG_NOTIFY === "notify") {
      console.log("FLAG_NOTIFY: Setting WebSocket Path to /notify")
      ws_path = "/notify"
    } else if (FLAG_NOTIFY === "socket.io") {
      console.log("FLAG_NOTIFY: Setting WebSocket Path to /socket.io")
      ws_path = "/socket.io"
    }

    this._socket = io(process.env.REACT_APP_FOREMAN_NOTIFY_URI, {
      path: ws_path,
      autoConnect: true,
      transports: ["websocket"],
      upgrade: true,
    })

    // log socketio events & errors
    this._socket.on("connect", () => {
      console.log("WebSocket: Request Authorization")
      this._connected = true
      this.foreman.get_access_token(access_token => {
        this.emit("Authorization", { token: access_token })
      })
    })
    this._socket.on("disconnect", reason => {
      this._connected = false
      console.log(`WebSocket: disconnect reason: ${reason}`)
    })
    this._socket.on("Authorization", data => {
      if (data.status === "Ok") {
        this._authorized = true
        console.log("WebSocket: Authorization Success")
        this._rooms.forEach(roomUuid => {
          console.log(`WebSocket: Join Room ${roomUuid}`)
          this._socket.emit("join", { room: roomUuid })
        })
      } else if (data.status === "Error") {
        console.log(`WebSocket: Authorization Error: ${data.error_message}`)
        this._authorized = false
      } else {
        console.log(`WebSocket: Authorization Failed: Unknown reason`)
        this._authorized = false
      }
    })
    this._socket.on("joined", data => {
      console.log(`WebSocket: Joined Room ${data.room}`)
    })
    this._socket.on("user", data => {
      console.log(`WebSocket: User ${data.user.uuid}`)
      this.foreman.get_current_user(user => {
        if (user.uuid === data.user.uuid) {
          localStorage.setItem("user", JSON.stringify(data.user))
        }
      })
    })
    this._socket.on("error", () => {
      console.error("WebSocket: error")
    })
    this._socket.on("connect_error", () => {
      console.error("WebSocket: connect_error")
    })
    this._socket.on("connect_timeout", () => {
      console.warn("WebSocket: connect_timeout")
    })
    this._socket.on("reconnect", () => {
      console.error("WebSocket: connect_error")
    })
    this._socket.on("reconnect_attempt", () => {
      console.log("WebSocket: reconnect_attempt")
    })
    this._socket.on("reconnecting", () => {
      console.log("WebSocket: reconnecting")
    })
    this._socket.on("reconnect_error", () => {
      console.error("WebSocket: reconnect_error")
    })
    this._socket.on("reconnect_failed", () => {
      console.error("WebSocket: reconnect_failed")
    })
  }

  static getInstance() {
    if (WebSocketManager.instance === null) {
      this.instance = new WebSocketManager()
    }

    return this.instance
  }

  getSocket = () => {
    return this._socket
  }

  isSocketOpen = () => {
    return this._socket.connected
  }

  openSocket = () => {
    if (!this.isSocketOpen()) {
      this._socket.open()
    }
  }

  closeSocket = () => {
    if (this.isSocketOpen()) {
      this._socket.disconnect()
    }
  }

  register = (event, callback) => {
    this._socket.off(event)
    this._socket.on(event, callback)
  }

  emit = (event, ...args) => {
    this._socket.emit(event, ...args)
  }

  addRoom = roomUuid => {
    this._rooms.add(roomUuid)
    if (this._connected && this._authorized) {
      this._socket.emit("join", { room: roomUuid })
      console.log(`Joined Room ${roomUuid}`)
    }
  }
}
