import React, { Component } from "react"
import PropTypes from "prop-types"
import "./HeaderButton.css"

class HeaderButton extends Component {
  onClick = event => {
    if (!this.props.disabled) {
      this.props.onClick(event)
    }
  }

  render() {
    let className = "header-button " + this.props.className
    if (this.props.disabled) {
      className += " disabled"
    }
    let icon = null
    if (this.props.icon) {
      icon = <i className={"fa fa-fw " + this.props.icon} aria-hidden="true" />
    }
    return (
      <div className={className} onClick={this.onClick}>
        <div className="header-button-btn">
          {icon} {this.props.text}
        </div>
      </div>
    )
  }
}

HeaderButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default HeaderButton
