import React, { Component } from "react"
import PropTypes from "prop-types"
import "./CircleButton.css"

class CircleButton extends Component {
  render() {
    let className = "circle-button " + this.props.className
    let iconClassName = "fa " + this.props.iconClassName
    return (
      <div className={className} onClick={this.props.onClick}>
        <div className="circle-button-btn">
          <i className={iconClassName} aria-hidden="true" />
        </div>
      </div>
    )
  }
}

CircleButton.propTypes = {
  iconClassName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default CircleButton
