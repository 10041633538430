import React, { Component } from "react"
import PropTypes from "prop-types"
import RoomItem from "./RoomItem"
import "./Rooms.css"

class Rooms extends Component {
  render() {
    let roomItems
    if (this.props.rooms) {
      let self = this
      roomItems = Object.keys(self.props.rooms).map(function(key, idx) {
        return (
          <RoomItem
            key={key}
            room={self.props.rooms[key]}
            messagesParams={self.props.messagesParams}
            slideout={self.props.slideout}
          />
        )
      })
    }
    return <div className="rooms">{roomItems}</div>
  }
}

Rooms.propTypes = {
  rooms: PropTypes.object.isRequired,
  messagesParams: PropTypes.object.isRequired,
}

export default Rooms
