import React, { Component } from "react"
import PropTypes from "prop-types"
import ConversationItem from "./ConversationItem"
import "./Conversations.css"

class Conversations extends Component {
  render() {
    let conversationItems
    let messagesParams = this.props.messagesParams
    let conversations = this.props.conversations
    if (this.props.conversations) {
      let self = this
      conversationItems = Object.keys(conversations).map(function(key, idx) {
        let conversation = conversations[key]
        return (
          <ConversationItem
            key={key}
            conversation={conversation}
            messagesParams={messagesParams}
            slideout={self.props.slideout}
          />
        )
      })
    }
    return <div id="conversations">{conversationItems}</div>
  }
}

Conversations.propTypes = {
  conversations: PropTypes.object.isRequired,
  messagesParams: PropTypes.object.isRequired,
}

export default Conversations
