import React, { Component } from "react"
import logo from "../../logo.png"
import "./Brand.css"

class Brand extends Component {
  render() {
    return (
      <div className="brand">
        <img src={logo} alt="Crew" />
      </div>
    )
  }
}

export default Brand
