import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import "./Actions.css"

class Actions extends Component {
  constructor(props) {
    super(props)
    this.handleNewMessageBtn = this.handleNewMessageBtn.bind(this)
  }

  handleNewMessageBtn(event) {
    let messagesParams = this.props.messagesParams
    let pathname = "/messages/new"
    if (messagesParams.roomUuid !== "new") {
      pathname = `/messages/${messagesParams.roomUuid}/new`
    }
    this.props.history.push({
      pathname: pathname,
    })
  }

  render() {
    return (
      <div className="actions">
        <div className="btn-new-msg btn btn-blue btn-circle" onClick={this.handleNewMessageBtn}>
          <span>
            New Message
            <i className="fa fa-comments fa-fw" />
          </span>
        </div>
      </div>
    )
  }
}

Actions.propTypes = {
  messagesParams: PropTypes.object.isRequired,
}

export default withRouter(Actions)
