import "./BetaBanner.css"

import React, { Component } from "react"

class BetaBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hideBanner: localStorage.getItem("hideBanner") || false,
    }
  }

  render() {
    return (
      <>
        {!this.state.hideBanner && (
          <div className="beta-wrapper">
            <div className="beta">
              <span>
                Try the Operator UI 2.0 -{" "}
                <a href="https://betamessenger.operatorapp.io/#/" target="_blank">
                  Click Here
                </a>
              </span>
            </div>
            <span
              className="x"
              onClick={() => {
                localStorage.setItem("hideBanner", true)
                this.setState({
                  hideBanner: true,
                })
              }}
            />
          </div>
        )}
      </>
    )
  }
}

export default BetaBanner
