import PropTypes from "prop-types"
import React, { Component } from "react"
import { DebounceInput } from "react-debounce-input"
import "./HeaderInput.css"
import SearchItems from "./SearchItems"

class HeaderInput extends Component {
  minQuerySize = 2

  constructor(props) {
    super(props)
    this.state = {
      inputValue: "",
      hasSearchItems: false,
    }
    this.onChange = this.onChange.bind(this)
    this.chooseNumber = this.chooseNumber.bind(this)
    this.searchItemChange = this.searchItemChange.bind(this)
  }

  onChange(event) {
    this.setState({
      inputValue: event.target.value,
    })
    this.props.onChange(event)
  }

  chooseNumber(number) {
    this.setState({ inputValue: number }, () => {
      this.props.onChange({ target: { name: this.props.name, value: number } })
    })
    this.props.onChange({ target: { value: number, name: this.props.name } })
  }

  searchItemChange(list, message) {
    if (message || (list && list.length > 0)) {
      this.setState({ hasSearchItems: true })
    } else {
      this.setState({ hasSearchItems: false })
    }
  }

  render() {
    return (
      <div className="header-input">
        <div className="header-input-background" />
        <div className="header-input-container">
          <DebounceInput
            minLength={1}
            debounceTimeout={500}
            name={this.props.name}
            type={this.props.type || "text"}
            pattern={this.props.pattern}
            onChange={this.onChange}
            onKeyUp={this.props.onKeyUp}
            value={this.state.inputValue}
          />
          <SearchItems
            minQuerySize={this.minQuerySize}
            query={this.state.inputValue}
            searchItemChange={this.searchItemChange}
            chooseNumber={this.chooseNumber}
          />
        </div>
        {this.state.hasSearchItems ? null : <p className="header-input-info">{this.props.info}</p>}
      </div>
    )
  }
}

HeaderInput.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  type: PropTypes.string,
  pattern: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
}

export default HeaderInput
