import React, { Component } from "react"
import PropTypes from "prop-types"
import MediaItem from "./MediaItem"
import "./MessageItem.css"

class MessageItem extends Component {
  render() {
    let mediaItems = this.props.message.media
      .filter(m => m != null)
      .map((m, idx) => <MediaItem url={m} direction={this.props.message.direction} />)
    // .map((m, idx) => <img src={m} alt="Message Attachment" />)
    let timestamp = null
    if (this.props.timestamps) {
      timestamp = this.props.message.received.format("MMM Do, h:mm:ss a")
    }
    let sender = null
    if (this.props.message.senderName) {
      sender = this.props.message.senderName
    }
    let subtext = null
    if (timestamp || sender) {
      subtext = (
        <div className="msg-timestamp">{(timestamp || "") + (timestamp && sender ? " | " : "") + (sender || "")}</div>
      )
    }

    return (
      <div className={`msg-box msg-box-${this.props.message.direction}`}>
        {mediaItems}
        <div className={`msg msg-${this.props.message.direction}`}>
          <p>{this.props.message.text}</p>
        </div>
        {subtext}
      </div>
    )
  }
}

MessageItem.propTypes = {
  message: PropTypes.object.isRequired,
}

export default MessageItem
