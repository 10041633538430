class Common {
  static formatNumber = (cc, number) => {
    if (cc === 1) {
      return number.replace(/^(\d{3})(\d{3})(\d{4})$/, `+${cc} ($1) $2-$3`)
    } else {
      return `+${cc}-${number}`
    }
  }
}

export default Common
