import React, { Component } from "react"
import PropTypes from "prop-types"
import Rooms from "./Rooms"
import Brand from "./Brand"
import Actions from "./Actions"
import "./RoomsColumn.css"

class RoomsColumn extends Component {
  render() {
    return (
      <div id="rooms-column">
        <Brand />
        <Rooms rooms={this.props.rooms} messagesParams={this.props.messagesParams} slideout={this.props.slideout} />
        <Actions messagesParams={this.props.messagesParams} toggleNewMessage={this.props.toggleNewMessage} />
      </div>
    )
  }
}

RoomsColumn.propTypes = {
  rooms: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  messagesParams: PropTypes.object.isRequired,
}

export default RoomsColumn
