import axios from "axios"
var jwt = require("jsonwebtoken")

class AuthenticationError {
  constructor(message) {
    this.message = message
    this.name = "AuthenticationError"
  }
}

export default class Foreman {
  static instance = null
  access_token = null
  access_token_expires = null
  refresh_token = null
  refresh_token_expires = null
  headers = {}

  constructor() {
    this.load_jwt()
  }

  static getInstance() {
    if (Foreman.instance === null) {
      this.instance = new Foreman()
    }
    return this.instance
  }

  load_jwt() {
    console.log(`Foreman.load_jwt()`)
    let tkn = JSON.parse(localStorage.getItem("token"))
    if (tkn) {
      this.access_token = tkn.access_token
      if (this.access_token) {
        this.access_token_expires = jwt.decode(this.access_token).exp
      }
      this.refresh_token = tkn.refresh_token
      if (this.refresh_token) {
        this.refresh_token_expires = jwt.decode(this.refresh_token).exp
      }
    }
  }

  set_jwt(access_token, refresh_token) {
    if (access_token) {
      this.access_token = access_token
      this.access_token_expires = jwt.decode(this.access_token).exp
      this.headers["Authorization"] = `Bearer ${this.access_token}`
    }
    if (refresh_token) {
      this.refresh_token = refresh_token
      this.refresh_token_expires = jwt.decode(this.refresh_token).exp
    }
    localStorage.setItem(
      "token",
      JSON.stringify({ access_token: this.access_token, refresh_token: this.refresh_token }),
    )
  }

  get_access_token(cb) {
    if (!this.is_authenticated()) {
      throw new AuthenticationError("Authentication Expired")
    }
    if (this.access_token && this.access_token_expires) {
      let now = Date.now() / 1000
      let expired = now >= this.access_token_expires
      let remaining = this.access_token_expires - now
      console.log(
        `Access Token Expires: ${this.access_token_expires} Now: ${now} Expired: ${expired} Remaining: ${remaining}`,
      )
      if (!expired) {
        cb(this.access_token)
        return
      } else {
        this.refresh_access_token(cb)
        return
      }
    }
    console.log("Error: Access Token Failed!")
  }

  refresh_access_token(cb) {
    // Refresh access token
    axios({
      method: "post",
      url: `${process.env.REACT_APP_FOREMAN_API_URI}/auth/jwt/refresh`,
      headers: { Authorization: `Bearer ${this.refresh_token}` },
    })
      .then(response => {
        console.log("Foreman: Refresh JWT Success")
        this.set_jwt(response.data.access_token, null)
        cb(response.data.access_token)
      })
      .catch(error => {
        console.log("Foreman: Refresh JWT Error")
        console.log(error)
        cb(null)
      })
  }

  is_authenticated() {
    if (this.refresh_token && this.refresh_token_expires) {
      return Date.now() / 1000 < this.refresh_token_expires
    }
    return false
  }

  login(email, password, cb) {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_FOREMAN_API_URI}/auth/jwt/login`,
      data: {
        email: email,
        password: password,
      },
    })
      .then(response => {
        console.log("Foreman: Authentication Success")
        this.set_jwt(response.data.access_token, response.data.refresh_token)
        this.get_current_user((data, errs) => {
          if (errs) {
            cb(false, errs)
          } else {
            cb(true, null)
          }
        })
      })
      .catch(error => {
        console.log("Foreman: Authentication Error")
        console.log(error)
        cb(false, [error.response.data.error_message])
      })
  }

  logout() {
    console.log("Foreman: Logout")
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    window.location.reload()
  }

  get_user(userUuid, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/users/${userUuid}`,
        headers: { Authorization: `Bearer ${access_token}` },
      })
        .then(response => {
          console.log("Foreman.get_user")
          cb(response.data, null)
        })
        .catch(error => {
          console.error(`Foreman: Error getting User: ${error}`)
          cb(null, [error.response.data.error_message])
        })
    })
  }

  get_current_user(cb) {
    // retrieve from localStorage
    let user = JSON.parse(localStorage.getItem("user"))
    if (user) {
      cb(user, null)
      return
    }
    // retrieve from foreman
    this.get_access_token(access_token => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/users/current`,
        headers: { Authorization: `Bearer ${access_token}` },
      })
        .then(response => {
          console.log("Foreman: Current User")
          localStorage.setItem("user", JSON.stringify(response.data))
          cb(response.data, null)
        })
        .catch(error => {
          console.log("Foreman: Error getting Current User")
          console.log(error)
          cb(null, [error.response.data.error_message])
        })
    })
  }

  update_current_user(name, signature, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "put",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/users/current`,
        headers: { Authorization: `Bearer ${access_token}` },
        data: {
          name,
          signature,
        },
      })
        .then(response => {
          console.log(`Foreman: Updated Current User!`)
          cb(true, null)
        })
        .catch(error => {
          console.log("Foreman: Update Current User failed )-:")
          console.log(error)
          cb(false, [error.response.data.message])
        })
    })
  }

  update_current_user_password(current_password, new_password, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "put",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/users/current`,
        headers: { Authorization: `Bearer ${access_token}` },
        data: {
          password: current_password,
          new_password: new_password,
        },
      })
        .then(response => {
          console.log(`Foreman: Updated Current User Password!`)
          cb(true, null)
        })
        .catch(error => {
          console.log("Foreman: Update Current User Password failed )-:")
          console.log(error)
          cb(false, [error.response.data.message])
        })
    })
  }

  create_contact(directoryUuid, name, phone_numbers, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/directories/${directoryUuid}/contacts`,
        headers: { Authorization: `Bearer ${access_token}` },
        data: {
          name: name,
          phone_numbers: phone_numbers,
        },
      })
        .then(response => {
          console.log(`Foreman: Created Contact ${response.data.uuid}!`)
          cb(response.data, null)
        })
        .catch(error => {
          console.log("Foreman: Contact Create failed )-:")
          console.log(error)
          cb(null, [error.response.data.error_message])
        })
    })
  }

  get_contacts(directoryUuid, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/directories/${directoryUuid}/contacts`,
        headers: { Authorization: `Bearer ${access_token}` },
      })
        .then(response => {
          console.log(`Foreman: Get Contacts ${directoryUuid}!`)
          cb(response.data, null)
        })
        .catch(error => {
          console.log("Foreman: Get Contacts failed )-:")
          console.log(error)
          cb(null, [error.response.data.error_message])
        })
    })
  }

  get_contact(directoryUuid, contactUuid, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/directories/${directoryUuid}/contacts/${contactUuid}/`,
        headers: { Authorization: `Bearer ${access_token}` },
      })
        .then(response => {
          console.log(`Foreman: Get Contact ${directoryUuid}/${contactUuid}!`)
          cb(response.data, null)
        })
        .catch(error => {
          console.log("Foreman: Get Contact failed )-:")
          console.log(error)
          cb(null, [error.response.data.error_message])
        })
    })
  }

  get_contact_conversations(contactUuid, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/conversations/?contact=${contactUuid}`,
        headers: { Authorization: `Bearer ${access_token}` },
      })
        .then(response => {
          console.log(`Foreman: Get Contact Conversations ${contactUuid}!`)
          cb(response.data, null)
        })
        .catch(error => {
          console.log("Foreman: Get Contact Conversations failed )-:")
          console.log(error)
          cb(null, [error.response.data.error_message])
        })
    })
  }

  delete_contact(directoryUuid, contactUuid, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/directories/${directoryUuid}/contacts/${contactUuid}/`,
        headers: { Authorization: `Bearer ${access_token}` },
      })
        .then(response => {
          console.log(`Foreman: Deleted Contact ${directoryUuid}/${contactUuid}!`)
          cb(true, null)
        })
        .catch(error => {
          console.log("Foreman: Delete Contact failed )-:")
          console.log(error)
          cb(false, [error.response.data.error_message])
        })
    })
  }

  update_contact(directoryUuid, contactUuid, name, phone_numbers, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "put",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/directories/${directoryUuid}/contacts/${contactUuid}/`,
        headers: { Authorization: `Bearer ${access_token}` },
        data: {
          name: name,
          phone_numbers: phone_numbers,
        },
      })
        .then(response => {
          console.log(`Foreman: Updated Contact ${response.data.uuid}!`)
          cb(response.data, null)
        })
        .catch(error => {
          console.log("Foreman: Update Contact failed )-:")
          console.log(error)
          cb(null, [error.response.data.error_message])
        })
    })
  }

  get_conversation(conversationUuid, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/conversations/${conversationUuid}`,
        headers: { Authorization: `Bearer ${access_token}` },
      }).then(resp => {
        cb(resp.data)
      })
    })
  }

  create_conversation(roomUuid, roomType, resource, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/conversations/`,
        headers: { Authorization: `Bearer ${access_token}` },
        data: {
          room_uuid: roomUuid,
          room_type: roomType,
          resource: resource,
        },
      }).then(resp => {
        cb(resp.data)
      })
    })
  }

  mark_conversation_read(conversationUuid, cb) {
    this.update_conversation(conversationUuid, { unread: 0 }, cb)
  }

  mark_conversation_unread(conversationUuid, cb) {
    this.update_conversation(conversationUuid, { unread: 1 }, cb)
  }

  archive_conversation(conversationUuid, cb) {
    this.update_conversation(conversationUuid, { state: "Archived" }, cb)
  }

  unarchive_conversation(conversationUuid, cb) {
    this.update_conversation(conversationUuid, { state: "Active" }, cb)
  }

  update_conversation(conversationUuid, data, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "put",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/conversations/${conversationUuid}`,
        headers: { Authorization: `Bearer ${access_token}` },
        data,
      })
        .then(response => {
          console.log(`Foreman: Conversation changed ${JSON.stringify(data)}!`)
          cb(true, null)
        })
        .catch(error => {
          console.log("Foreman: Conversation update failed )-:")
          console.log(error)
          cb(false, [error.response.data.error_message])
        })
    })
  }

  get_conversation_messages(conversationUuid, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/conversations/${conversationUuid}/messages`,
        headers: { Authorization: `Bearer ${access_token}` },
      }).then(resp => {
        cb(resp.data)
      })
    })
  }

  create_conversation_message(conversationUuid, message, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/conversations/${conversationUuid}/messages`,
        headers: { Authorization: `Bearer ${access_token}` },
        data: {
          message: message,
        },
      })
        .then(response => {
          console.log(`Foreman: Created Message ${response.data.uuid}!`)
          cb(response.data, null)
        })
        .catch(error => {
          console.log("Foreman: Create Message failed )-:")
          console.log(error)
          cb(null, [error.response.data.error_message])
        })
    })
  }

  get_rooms_list(cb) {
    this.get_access_token(access_token => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/rooms/`,
        headers: { Authorization: `Bearer ${access_token}` },
      }).then(resp => {
        cb(resp.data)
      })
    })
  }

  get_room_conversations(roomUuid, cb) {
    this.get_access_token(access_token => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/rooms/${roomUuid}/conversations`,
        headers: { Authorization: `Bearer ${access_token}` },
      }).then(resp => {
        cb(resp.data)
      })
    })
  }

  search_contact(query, callback) {
    this.get_access_token(access_token => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_FOREMAN_API_URI}/directories/contacts/search`,
        headers: { Authorization: `Bearer ${access_token}` },
        params: { query },
      }).then(resp => {
        callback(resp.data)
      })
    })
  }
  save_webex_user_auth(userUuid, code) {
    let self = this
    return new Promise(function(resolve, reject) {
      self.get_access_token(access_token => {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_FOREMAN_API_URI}/webex/link-user/`,
          headers: { Authorization: `Bearer ${access_token}` },
          data: {
            userUuid,
            code,
          },
        })
          .then(function(response) {
            console.log("Foreman.save webex user auth")
            return resolve(response.data)
          })
          .catch(function(error) {
            console.error(`Foreman: Error saving webex user auth: ${error}`)
            return reject(error)
          })
      })
    })
  }

  save_webex_user_id(userUuid, webex_id) {
    let self = this
    return new Promise(function(resolve, reject) {
      self.get_access_token(access_token => {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_FOREMAN_API_URI}/webex/user-id/`,
          headers: { Authorization: `Bearer ${access_token}` },
          data: {
            userUuid,
            webex_id,
          },
        })
          .then(function(response) {
            console.log("Foreman.save webex user id")
            return resolve(response.data)
          })
          .catch(function(error) {
            console.error(`Foreman: Error saving webex user id: ${error}`)
            return reject(error)
          })
      })
    })
  }
}
