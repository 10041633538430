import "./SettingsApp.css"
import "./slideout.css"

import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import Slideout from "slideout"

import BetaBanner from "./components/BetaBanner"
import Brand from "./components/Brand"
import SettingsProfile from "./components/SettingsProfile"
import SettingsSecurity from "./components/SettingsSecurity"
import Sidebar from "./components/Sidebar"

class SettingsApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      f_name: this.props.name || "",
      f_email: this.props.email || "",
      slideout: null,
    }
  }

  componentDidMount() {
    let slideout = new Slideout({
      panel: document.getElementById("panel"),
      menu: document.getElementById("slideout"),
      padding: 350,
      tolerance: 70,
    })
    this.setState({ slideout: slideout })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSectionClick = pathname => event => {
    this.props.history.push({
      pathname: pathname,
    })
    this.state.slideout.close()
  }

  render() {
    let section = null
    let profileClasses = "settings-selection"
    let securityClasses = "settings-selection"
    let navParams = {
      section: this.props.computedMatch.params.section,
    }
    switch (navParams.section) {
      case "profile":
        section = <SettingsProfile />
        profileClasses += " active"
        break
      case "security":
        section = <SettingsSecurity />
        securityClasses += " active"
        break
      default:
        console.log(`No section found, redirecting to Profile`)
        this.props.history.push({
          pathname: `/settings/profile`,
        })
    }

    return (
      <div id="settings-app">
        <div id="slideout">
          <Sidebar selected="settings" slideout={this.state.slideout} />
          <div id="settings-column">
            <Brand />
            <div className="settings-selections">
              <div className="settings-selections-header">Settings</div>
              <div className={profileClasses} onClick={this.handleSectionClick("/settings/profile")}>
                Profile
              </div>
              <div className={securityClasses} onClick={this.handleSectionClick("/settings/security")}>
                Security
              </div>
            </div>
          </div>
        </div>
        <div id="panel">
          <BetaBanner />
          <div className="settings-header">
            <i
              className="menu fa fa-bars"
              onClick={() => {
                this.state.slideout.toggle()
              }}
            />
            <div className="settings-header-msg">
              <i className="fa fa-cog" />
              {navParams.section} Settings
            </div>
          </div>
          {section}
        </div>
      </div>
    )
  }
}

export default withRouter(SettingsApp)
