import React, { Component } from "react"
import PropTypes from "prop-types"
import FormSelectItem from "./FormSelectItem"
import "./FormSelect.css"

class FormSelect extends Component {
  render() {
    let options = this.props.options.map((option, idx) => (
      <FormSelectItem key={idx} name={option.name} value={option.value} selected={option.selected} />
    ))

    return (
      <div className={"form-select " + this.props.className}>
        <div className="form-select-background" />
        <div className="form-select-container">
          <select
            name={this.props.name}
            defaultValue={this.props.defaultValue}
            disabled={this.props.disabled}
            value={this.props.value}
            onChange={this.props.onChange}
          >
            {options}
          </select>
        </div>
        <p className="form-select-info">{this.props.info}</p>
      </div>
    )
  }
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  info: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default FormSelect
