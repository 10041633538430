import React, { Component } from "react"
import PropTypes from "prop-types"
import Conversations from "./Conversations"
import "./RoomItem.css"

class RoomItem extends Component {
  constructor(props) {
    super(props)
    this.handleRoomToggle = this.handleRoomToggle.bind(this)
    this.handleNewConversation = this.handleNewConversation.bind(this)
    this.state = {
      expand: true,
    }
  }

  handleRoomToggle(event) {
    console.log(`handleRoomToggle: ${event}`)
    this.setState({ expand: !this.state.expand })
  }

  handleNewConversation(event) {
    console.log(`handleNewConversation: ${event}`)
  }

  render() {
    let room = this.props.room
    var classExpandName
    var conversations
    if (this.state.expand) {
      classExpandName = "fa fa-chevron-down"
      conversations = (
        <Conversations
          conversations={room.conversations}
          messagesParams={this.props.messagesParams}
          slideout={this.props.slideout}
        />
      )
    } else {
      classExpandName = "fa fa-chevron-right"
      conversations = null
    }
    return (
      <div className="room" room-uuid={room.uuid}>
        <div className="room-header">
          <span className="room-header-expand" onClick={this.handleRoomToggle}>
            <i className={classExpandName} />
          </span>
          <span className="room-header-name">{room.name}</span>
        </div>
        {conversations}
      </div>
    )
  }
}

RoomItem.propTypes = {
  room: PropTypes.object.isRequired,
  messagesParams: PropTypes.object.isRequired,
}

export default RoomItem
