import React, { Component } from "react"
import PropTypes from "prop-types"
import "./ConversationItem.css"
import { withRouter } from "react-router-dom"

class ConversationItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      conversation: props.conversation,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = event => {
    let conversation = this.props.conversation
    console.log(`handleClick: Conversation ${conversation.roomUuid}/${conversation.uuid}`)
    this.props.history.push({
      pathname: `/messages/${conversation.roomUuid}/${conversation.uuid}`,
    })
    this.props.slideout.close()
  }

  render() {
    let conversation = this.state.conversation
    let icon = null
    if (conversation.unread > 0) {
      icon = <i className="fa fa-circle-o yellow" />
    }
    if (conversation.state === "Archived") {
      icon = <i className="fa fa-archive red" />
    }
    let cssClasses = "conversation"
    if (this.props.messagesParams.conversationUuid === conversation.uuid) {
      cssClasses += " conversation-active"
    }
    return (
      <div key={this.props.key} className={cssClasses} onClick={this.handleClick}>
        <span className="type">
          <i className="fa fa-mobile" />
        </span>
        <span className="name">
          {conversation.displayName} {icon}
        </span>
      </div>
    )
  }
}

ConversationItem.propTypes = {
  conversation: PropTypes.object.isRequired,
  messagesParams: PropTypes.object.isRequired,
}

export default withRouter(ConversationItem)
