import React, { Component } from "react"
import logo from "../../logo.png"
import Branding from "../../branding"
import "./Header.css"

class Header extends Component {
  render() {
    return (
      <div id="header">
        <div className="brand">
          <img src={logo} alt={Branding.LOGO_ALT} />
        </div>
        <span className="text">{Branding.LOGIN_BANNER}</span>
      </div>
    )
  }
}

export default Header
