import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import Foreman from "./libraries/Foreman"

import Brand from "./components/Brand"
import Common from "./components/Common"
import ComboButton from "./components/ComboButton"
import ComboItem from "./components/ComboItem"
import Sidebar from "./components/Sidebar"
import Slideout from "slideout"

import "./css/Directory.css"
import "./ContactDetail.css"
import "./slideout.css"

var moment = require("moment")

class ContactDetail extends Component {
  constructor(props) {
    super(props)
    this.foreman = Foreman.getInstance()
    this.state = {
      contact: {
        uuid: "",
        name: "Loading",
        phone_numbers: [],
      },
      conversations: [],
      directories: [],
      slideout: null,
    }
  }

  componentWillMount() {
    let navParams = {
      directoryUuid: this.props.computedMatch.params.directoryUuid,
      contactUuid: this.props.computedMatch.params.contactUuid,
    }
    this.loadDirectories()
    this.loadContact(navParams.directoryUuid, navParams.contactUuid)
  }

  componentDidMount() {
    let slideout = new Slideout({
      panel: document.getElementById("panel"),
      menu: document.getElementById("slideout"),
      padding: 350,
      tolerance: 70,
    })
    this.setState({ slideout: slideout })
  }

  loadDirectories() {
    console.log("ContactDetail.loadDirectories")
    let user = JSON.parse(localStorage.getItem("user"))
    let directories = {}
    for (let directory of user.directories) {
      directories[directory.uuid] = directory
    }
    // Get default directory
    this.setState({
      directories: directories,
    })
  }

  loadContact = (directoryUuid, contactUuid) => {
    console.log(`Loading Contact ${directoryUuid}/${contactUuid}`)
    // get contact
    this.foreman.get_contact(directoryUuid, contactUuid, (data, errs) => {
      if (errs) {
        console.error("Could not retrieve contact information!")
        console.log(errs)
      } else {
        console.log("Contact Loaded")
        this.setState({ contact: data })
      }
    })
    // get conversations
    this.foreman.get_contact_conversations(contactUuid, (data, errs) => {
      if (errs) {
        console.error("Could not retrieve conversations!")
        console.log(errs)
      } else {
        console.log("Conversations Loaded")
        this.setState({ conversations: data })
      }
    })
  }

  editContact = event => {
    let navParams = {
      directoryUuid: this.props.computedMatch.params.directoryUuid,
      contactUuid: this.props.computedMatch.params.contactUuid,
    }
    console.log("Contact Edit")
    this.props.history.push({
      pathname: `/contacts/${navParams.directoryUuid}/${navParams.contactUuid}/edit`,
      state: {
        f_uuid: navParams.contactUuid,
        f_directory: navParams.directoryUuid,
        f_name: this.state.contact.name,
        f_numbers: this.state.contact.phone_numbers.map((number, idx) => {
          return { number: number.number, type: number.type || "Mobile" }
        }),
      },
    })
  }

  deleteContact = event => {
    let navParams = {
      directoryUuid: this.props.computedMatch.params.directoryUuid,
      contactUuid: this.props.computedMatch.params.contactUuid,
    }
    console.log(`Deleting Contact ${navParams.directoryUuid}/${navParams.contactUuid}`)
    this.foreman.delete_contact(navParams.directoryUuid, navParams.contactUuid, (success, errs) => {
      if (errs) {
        console.error("Could not delete contact!")
        console.log(errs)
      } else {
        console.log("Contact Deleted")
        this.props.history.push({
          pathname: `/contacts/${navParams.directoryUuid}`,
        })
      }
    })
  }

  handleSectionClick = pathname => event => {
    this.props.history.push({
      pathname: pathname,
    })
    this.state.slideout.close()
  }

  render() {
    let navParams = {
      directoryUuid: this.props.computedMatch.params.directoryUuid,
      contactUuid: this.props.computedMatch.params.contactUuid,
    }
    console.log("Contact Detail")
    console.log(navParams)
    console.log(this.state)

    let contact = this.state.contact
    let conversations = this.state.conversations
    let directories = Object.values(this.state.directories).map(directory => {
      let classNames = "directories-selection"
      if (directory.uuid === this.props.computedMatch.params.directoryUuid) {
        classNames += " active"
      }
      return (
        <div className={classNames} onClick={this.handleSectionClick(`/contacts/${directory.uuid}`)}>
          <i className="fa fa-address-card" />
          {directory.name}
        </div>
      )
    })

    return (
      <div id="directory">
        <div id="slideout">
          <Sidebar selected="contacts" slideout={this.state.slideout} />
          <div id="directories-column">
            <Brand />
            <div className="directories-selections">
              <div className="directories-selections-header">Directories</div>
              {directories}
            </div>
          </div>
        </div>
        <div id="panel">
          <div className="content">
            <div className="directories-header">
              <i
                className="menu fa fa-bars"
                onClick={() => {
                  this.state.slideout.toggle()
                }}
              />
              <div className="directories-header-msg">
                <i className="fa fa-address-card" />
                {contact.name}
              </div>
              <div className="directories-header-fill" />
            </div>
            <div className="body">
              <div className="row row-right">
                <ComboButton>
                  <ComboItem className="blue" icon="fa-edit" text="Edit" onClick={this.editContact} />
                  <ComboItem className="red" icon="fa-trash" text="Delete" onClick={this.deleteContact} />
                </ComboButton>
              </div>
              <div className="row">
                <div className="col-md-12 header">Phone Numbers</div>
              </div>
              <div className="row">
                <div className="col-md-12 table-info contact-detail-phone">
                  {contact.phone_numbers.map((number, idx) => (
                    <span key={idx}>
                      <i className="fa fa-mobile" />
                      {Common.formatNumber(number.cc, number.number)}
                    </span>
                  ))}
                </div>
              </div>

              <h2 className="table-header">Conversations</h2>
              <div className="row conversations">
                <div className="col-xs-4 col-md-4 header">State</div>
                <div className="col-xs-8 col-md-6 header">Last Message</div>
                <div className="hidden-xs col-md-2 header">Messages</div>
              </div>
              {conversations.map((conversation, idx) => (
                <div key={conversation.uuid} className="row">
                  <div className="col-xs-4 col-md-4 table-info">Active</div>
                  <div className="col-xs-8 col-md-6 table-info">{moment(conversation.last_message).fromNow()}</div>
                  <div className="hidden-xs col-md-2 table-info">{conversation.message_count}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ContactDetail.propTypes = {}

export default withRouter(ContactDetail)
