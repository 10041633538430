import React, { Component } from "react"
import PropTypes from "prop-types"
import "./ComboButton.css"

class ComboButton extends Component {
  render() {
    return <div className="combo-btn">{this.props.children}</div>
  }
}

ComboButton.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ComboButton
