import React, { Component } from "react"
import PropTypes from "prop-types"

import "./Alert.css"

class Alert extends Component {
  render() {
    let className = `alert ${this.props.className}`
    let icon = `fa ${this.props.icon}`
    return (
      <div className={className}>
        <i className={icon} /> {this.props.message}
      </div>
    )
  }
}

Alert.propTypes = {
  className: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default Alert
