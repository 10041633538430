import "./MessagesApp.css"
import "./slideout.css"

import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import Slideout from "slideout"

import BetaBanner from "./components/BetaBanner"
import Messages from "./components/Messages"
import NewMessage from "./components/NewMessage"
import RoomsColumn from "./components/RoomsColumn"
import Sidebar from "./components/Sidebar"
import Foreman from "./libraries/Foreman"
import WebSocketManager from "./libraries/WebSocketManager"

const moment = require("moment")

class MessagesApp extends Component {
  constructor(props) {
    console.log("App Constructor!")
    super(props)
    this.state = {
      user: {},
      rooms: {},
      usersNames: {},
      newMessage: false,
      slideout: null,
      title: document.title,
    }
    this.socket_manager = WebSocketManager.getInstance()
    this.foreman = Foreman.getInstance()
    this.registerSocketEvents()
  }

  componentDidMount() {
    let slideout = new Slideout({
      panel: document.getElementById("panel"),
      menu: document.getElementById("slideout"),
      padding: 350,
      tolerance: 70,
    })
    this.setState({ slideout: slideout })

    // get current user
    this.foreman.get_current_user(data => {
      this.setState({ user: data })
    })
    // get rooms
    this.retreiveRooms()
    // request Notification permission
    if (typeof Notification !== "undefined" && Notification.permission !== "granted") {
      Notification.requestPermission()
    }
    this.updateNotificationCount()
  }

  componentDidUpdate = prevProps => {
    if (this.props.location !== prevProps.location) {
      this.state.slideout.close()
    }
  }

  addConversation = (roomUuid, newConversation, message) => {
    console.log(`Add conversation for ${roomUuid}/${newConversation.uuid}`)
    let rooms = this.state.rooms
    if (!(roomUuid in rooms)) {
      console.error("[ERROR] Room does not exist :-(")
      return
    }
    let conversation = this.getConversation(roomUuid, newConversation.uuid)
    if (conversation == null) {
      conversation = this.parseConversation(roomUuid, newConversation)
    } else {
      conversation = this.mergeConversation(conversation, newConversation)
    }
    rooms[roomUuid].conversations[conversation.uuid] = conversation
    this.setState({ rooms: rooms })
    this.updateNotificationCount()

    if (message) {
      this.addMessage(roomUuid, conversation.uuid, message, true)
    } else {
      this.loadConversationMessages(conversation)
    }

    // Select this conversation if none has been selected
    let selConversationUuid = this.props.computedMatch.params.conversationUuid
    if (selConversationUuid == null) {
      conversation.select = true
    }

    if (conversation.select) {
      this.props.history.push({
        pathname: `/messages/${roomUuid}/${conversation.uuid}`,
      })
    }
  }

  addMessage = (roomUuid, conversationUuid, message, initial = false) => {
    if (!initial) {
      console.log(`Add message ${roomUuid}/${conversationUuid}`)
    }
    let conversation = this.getConversation(roomUuid, conversationUuid)
    if (!(message.uuid in conversation.messages)) {
      message.received = moment(message.received)
      if (message.sender) {
        let usersNames = this.state.usersNames
        let sender = usersNames[message.sender]
        if (sender) {
          message.senderName = sender
        } else if (message.sender) {
          this.foreman.get_user(message.sender, data => {
            if (data && data.name) {
              usersNames[message.sender] = data.name
              message.senderName = data.name
              this.setState({ usersNames })
            }
          })
        }
      }
      conversation.messages[message.uuid] = message
      if (message.direction === "inbound" && !initial) {
        conversation.unread++
        if (conversationUuid === this.props.computedMatch.params.conversationUuid) {
          this.updateNotificationCount()
        } else {
          this.messageNotification(roomUuid, conversationUuid, conversation.displayName, message.text)
        }
      }
      let rooms = this.state.rooms
      let room = rooms[roomUuid]
      room.conversations[conversationUuid] = conversation
      rooms[roomUuid] = room
      this.setState({ rooms: rooms })
    } else {
      console.log(`Message UUID ${message.uuid} was in conversation ${conversationUuid} already`)
    }
  }

  addRoom = (roomUuid, roomName, roomType, roomUseSignature, roomDirectory) => {
    let rooms = this.state.rooms
    var room = {
      conversations: {},
      name: roomName,
      uuid: roomUuid,
      type: roomType,
      useSignature: roomUseSignature,
      directory: roomDirectory,
    }
    rooms[roomUuid] = room
    this.setState({
      rooms: rooms,
    })
    this.socket_manager.addRoom(roomUuid)
    let addConversation = this.addConversation
    this.foreman.get_room_conversations(roomUuid, data => {
      const convoUuids = []
      for (var idx in data) {
        let conversation = data[idx]
        convoUuids.push(conversation.uuid)
        addConversation(roomUuid, conversation, null)
      }
      const routeParams = this.props.computedMatch.params
      if (roomUuid === routeParams.roomUuid && !convoUuids.includes(routeParams.conversationUuid)) {
        this.props.history.push({
          pathname: `/messages/${roomUuid}/${convoUuids[0]}`,
        })
      }
    })
  }

  getConversation = (roomUuid, conversationUuid) => {
    let rooms = this.state.rooms
    if (rooms.hasOwnProperty(roomUuid)) {
      let room = rooms[roomUuid]
      if (room.conversations.hasOwnProperty(conversationUuid)) {
        return room.conversations[conversationUuid]
      }
    }
    return null
  }

  getRoom = roomUuid => {
    let rooms = this.state.rooms
    if (rooms.hasOwnProperty(roomUuid)) {
      return rooms[roomUuid]
    }
    return null
  }

  handleReceiveConversation = data => {
    var roomUuid = data["roomUuid"]
    var conversation = data["conversation"]
    console.log(`Receive conversation for ${roomUuid}`)
    this.addConversation(roomUuid, conversation, null)
  }

  handleReceiveConversationRead = data => {
    var roomUuid = data["room"]
    var conversationUuid = data["conversation"]
    console.log(`Receive conversation read for ${roomUuid}/${conversationUuid}`)
    let rooms = this.state.rooms
    let room = rooms[roomUuid]
    // update selected conversation
    let conversation = room.conversations[conversationUuid]
    conversation.unread = 0
    rooms[roomUuid].conversations[conversationUuid] = conversation
    // set state
    this.setState({ rooms: rooms })
  }

  handleReceiveMessage = data => {
    console.log(`Receive message`)
    let roomUuid = data.roomUuid
    let conversationUuid = data.message.conversation
    let message = data.message
    let conversation = this.getConversation(roomUuid, conversationUuid)
    if (conversation) {
      console.log("using stored conversation")
      // add message
      this.addMessage(roomUuid, conversationUuid, message)
    } else {
      // add conversation: retrieve conversation data if not present
      if ("conversation" in data) {
        console.log("using embedded conversation")
        this.addConversation(data.conversation.room_uuid, data.conversation, message)
      } else {
        console.log(`using remote conversation ${conversationUuid}`)
        let addConversation = this.addConversation
        this.foreman.get_conversation(conversationUuid, data => {
          addConversation(data, message)
        })
      }
    }
  }

  retreiveRooms = () => {
    console.log(`Retreive rooms`)
    const rooms = this.state.rooms
    let addRoom = this.addRoom
    this.foreman.get_rooms_list(data => {
      for (var idx in data) {
        var room = data[idx]
        if (!(room.uuid in rooms)) {
          addRoom(room.uuid, room.name, room.type, room.use_signature, room.directory)
        }
      }
    })
  }

  loadConversationMessages = conversation => {
    let addMessage = this.addMessage
    this.foreman.get_conversation_messages(conversation.uuid, data => {
      for (var idx in data) {
        addMessage(conversation.roomUuid, conversation.uuid, data[idx], true)
      }
    })
  }

  messageNotification = (roomUuid, conversationUuid, from, messageText) => {
    console.log("messageNotification")
    if (typeof window.Notification === "undefined" || !window.Notification) {
      console.log("Notifications not available in your browser..")
      return
    }

    let title = `New message from ${from}`
    let options = {
      body: messageText,
      icon: "../logo.png",
      dir: "auto",
    }

    let notification = null
    // send notification
    try {
      if (Notification.permission === "granted") {
        notification = new Notification(title, options)
      }
    } catch (e) {
      // ignore errors here, I'll figure out Chrome Mobile later
    }
    this.updateNotificationCount()
    if (notification) {
      console.log("Setting notification callbacks")
      let l_history = this.props.history
      notification.onclick = function() {
        console.log("Notification clicked")
        l_history.push({
          pathname: `/messages/${roomUuid}/${conversationUuid}`,
        })
        window.focus()
      }
      notification.onclose = function() {
        console.log("Notification dismissed")
      }
    }
  }
  updateNotificationCount = () => {
    let notifications = 0
    const rooms = this.state.rooms
    for (const roomUuid of Object.keys(rooms)) {
      const conversations = rooms[roomUuid].conversations
      for (const convoUuid of Object.keys(conversations)) {
        const convo = conversations[convoUuid]
        notifications += convo.unread
      }
    }
    document.title = notifications ? `${this.state.title} (${notifications})` : this.state.title
  }
  parseConversation = (roomUuid, conversation) => {
    console.log(`Parse conversation ${roomUuid}/${conversation.uuid}`)
    // Update conversation name
    conversation.displayName = conversation.resource
    conversation.messages = {}
    conversation.roomUuid = roomUuid
    if (conversation.hasOwnProperty("contact") && conversation.contact && conversation.contact.hasOwnProperty("name")) {
      conversation.displayName = conversation.contact.name
    }
    return conversation
  }

  mergeConversation = (conversation, newConversation) => {
    console.log(`Merge conversation ${conversation.uuid}`)
    // Update conversation name
    conversation.displayName = newConversation.resource
    conversation.unread = newConversation.unread
    conversation.state = newConversation.state
    conversation.contact = newConversation.contact
    conversation.select = newConversation.select
    if (
      newConversation.hasOwnProperty("contact") &&
      newConversation.contact &&
      newConversation.contact.hasOwnProperty("name")
    ) {
      conversation.displayName = newConversation.contact.name
    }
    return conversation
  }

  registerSocketEvents = () => {
    // socketio
    this.socket_manager.register("message_received", data => this.handleReceiveMessage(data))
    this.socket_manager.register("conversation", data => this.handleReceiveConversation(data))
  }

  sendConversationRead = conversationUuid => {
    console.log(`sendConversationRead ${conversationUuid}`)
    this.foreman.mark_conversation_read(conversationUuid, (success, errs) => {
      if (errs) {
        console.log("Could not mark conversation read!")
        console.log(errs)
      } else {
        console.log(`Conversation marked read ${conversationUuid}`)
      }
    })
  }

  sendCreateConversation = (roomUuid, resource) => {
    console.log(`sendCreateConversation(roomUuid=${roomUuid}, resource=${resource})`)
    let room = this.state.rooms[roomUuid]
    let addConversation = this.addConversation
    this.foreman.create_conversation(roomUuid, room.type, resource, conversation => {
      console.log("conversation created")
      conversation.select = true
      addConversation(roomUuid, conversation, null)
    })
  }

  sendMessage = (roomUuid, conversationUuid, message) => {
    this.foreman.create_conversation_message(conversationUuid, message, (msg, errs) => {
      if (errs) {
        console.log("Could not send message!")
        console.log(errs)
      } else {
        console.group(`Sent Message ${roomUuid}/${conversationUuid} => ${message}`)
        console.log(msg)
        console.groupEnd()

        // Mark as read if unread and a group conversation
        let room = this.state.rooms[roomUuid]
        let conversation = this.getConversation(roomUuid, conversationUuid)
        if (room && conversation && room.type === "BroadcastGroup" && conversation.unread) {
          this.sendConversationRead(conversationUuid)
        }

        this.addMessage(roomUuid, conversationUuid, msg)
      }
    })
  }

  render() {
    let messagesParams = {
      roomUuid: this.props.computedMatch.params.roomUuid
        ? this.props.computedMatch.params.roomUuid
        : Object.keys(this.state.rooms)[0],
      conversationUuid: this.props.computedMatch.params.conversationUuid,
    }

    // Mark as read if unread and an individual conversation
    let room = this.state.rooms[messagesParams.roomUuid]
    let conversation = this.getConversation(messagesParams.roomUuid, messagesParams.conversationUuid)
    if (room && conversation && room.type === "User" && conversation.unread) {
      this.sendConversationRead(messagesParams.conversationUuid)
    }
    messagesParams.archived = conversation && conversation.state === "Archived"

    var messages
    if (messagesParams.roomUuid === "new" || messagesParams.conversationUuid === "new") {
      messages = (
        <NewMessage
          rooms={this.state.rooms}
          messagesParams={messagesParams}
          sendCreateConversation={this.sendCreateConversation}
          slideout={this.state.slideout}
        />
      )
    } else {
      messages = (
        <Messages
          user={this.state.user}
          rooms={this.state.rooms}
          messagesParams={messagesParams}
          sendMessage={this.sendMessage}
          slideout={this.state.slideout}
        />
      )
    }

    return (
      <div id="messages-app">
        <div id="slideout">
          <Sidebar selected="messages" slideout={this.state.slideout} />
          <RoomsColumn
            user={this.state.user}
            rooms={this.state.rooms}
            messagesParams={messagesParams}
            slideout={this.state.slideout}
          />
        </div>
        <div id="panel">
          <BetaBanner />
          {messages}
        </div>
      </div>
    )
  }
}

export default withRouter(MessagesApp)
