import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import Common from "./Common"
import "./ContactItem.css"

class ContactItemPhone extends Component {
  render() {
    let formattedNumber = Common.formatNumber(this.props.number.cc, this.props.number.number)
    return (
      <span>
        <i className="fa fa-mobile" />
        {formattedNumber}
      </span>
    )
  }
}

class ContactItem extends Component {
  handleItemClick = event => {
    this.props.history.push({
      pathname: `/contacts/${this.props.directoryUuid}/${this.props.contactUuid}`,
    })
  }

  render() {
    let displayNumbers = this.props.numbers.map((number, idx) => <ContactItemPhone key={idx} number={number} />)

    return (
      <div className="tbl-row" onClick={this.handleItemClick}>
        <div className="tbl-cell tbl-name">{this.props.name}</div>
        <div className="tbl-cell tbl-number">{displayNumbers}</div>
      </div>
    )
  }
}

ContactItem.propTypes = {
  name: PropTypes.string.isRequired,
  directoryUuid: PropTypes.string.isRequired,
  contactUuid: PropTypes.string.isRequired,
  numbers: PropTypes.array.isRequired,
}

export default withRouter(ContactItem)
