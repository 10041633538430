import React, { Component } from "react"
import Foreman from "../libraries/Foreman"
import Header from "../../auth/components/Header"
import "../../../node_modules/font-awesome/css/font-awesome.min.css"
import "../../auth/Auth.css"

class WebexUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: "Your WebEx account is being linked...",
    }
    this.foreman = Foreman.getInstance()
  }

  async componentWillMount() {
    const webex_id = new URLSearchParams(this.props.location.search).get("webex_id")
    if (webex_id) {
      this.foreman.get_current_user(async user => {
        const result = await this.foreman.save_webex_user_id(user.uuid, webex_id)
        this.setState({ text: result.text })
      })
    } else {
      const code = new URLSearchParams(this.props.location.search).get("code")
      const userUuid = new URLSearchParams(this.props.location.search).get("state")
      const result = await this.foreman.save_webex_user_auth(userUuid, code)
      this.setState({ text: result.text })
    }
  }
  render() {
    return (
      <div className="login">
        <div className="login-header">
          <Header />
        </div>
        <div className="login-form">
          <div>{this.state.text}</div>
        </div>
      </div>
    )
  }
}

export default WebexUser
