import React, { Component } from "react"
import PropTypes from "prop-types"
import "./ComboItem.css"

class ComboItem extends Component {
  render() {
    return (
      <div className={"combo-item " + this.props.className} onClick={this.props.onClick}>
        <i className={"combo-item-icon fa " + this.props.icon} />
        <span className="combo-item-text">{this.props.text}</span>
      </div>
    )
  }
}

ComboItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default ComboItem
