import "./ContactsList.css"
import "./css/Directory.css"
import "./css/Table.css"
import "./slideout.css"

import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import Slideout from "slideout"

import BetaBanner from "./components/BetaBanner"
import Brand from "./components/Brand"
import CircleButton from "./components/CircleButton"
import ContactItem from "./components/ContactItem"
import Sidebar from "./components/Sidebar"
import Foreman from "./libraries/Foreman"

class ContactsList extends Component {
  constructor(props) {
    super(props)
    this.foreman = Foreman.getInstance()
    this.state = {
      contacts: [],
      directory: { uuid: "", name: "" },
      directories: [],
      slideout: null,
    }
    this.loadDirectories = this.loadDirectories.bind(this)
  }

  componentDidMount() {
    console.log("ContactsList.componentDidMount")
    this.loadDirectories()
    let slideout = new Slideout({
      panel: document.getElementById("panel"),
      menu: document.getElementById("slideout"),
      padding: 350,
      tolerance: 70,
    })
    this.setState({ slideout: slideout })
  }

  componentDidUpdate(prevProps) {
    console.log("ContactsList.componentDidUpdate")
    if (this.props.computedMatch.params.directoryUuid !== prevProps.computedMatch.params.directoryUuid) {
      let directoryUuid = this.props.computedMatch.params.directoryUuid
      if (directoryUuid == null) {
        this.redirectToFirstDirectory()
      } else {
        this.loadDirectories()
      }
    }
  }

  loadDirectories() {
    console.log("ContactsList.loadDirectories")
    let directoryUuid = this.props.computedMatch.params.directoryUuid
    if (directoryUuid == null) {
      this.redirectToFirstDirectory()
    } else {
      // Retrieve directorys
      let user = JSON.parse(localStorage.getItem("user"))
      let directories = {}
      for (let directory of user.directories) {
        directories[directory.uuid] = directory
      }
      let directory = directories[directoryUuid]
      this.setState({
        directory: directory,
        directories: directories,
      })

      // Retrieve Contacts
      this.foreman.get_contacts(directoryUuid, (data, errs) => {
        if (errs) {
          console.log("Could not retrieve contacts!")
          console.log(errs)
        } else {
          console.log("Contacts Loaded")
          if (this.state.directory.uuid === directoryUuid) {
            this.setState({ contacts: data })
          }
        }
      })
    }
  }

  handleSectionClick = pathname => event => {
    this.props.history.push({
      pathname: pathname,
    })
    this.state.slideout.close()
  }

  handleNewContact = event => {
    console.log("new click")
    this.props.history.push({
      pathname: `/contacts/new`,
      state: {
        f_directory: this.state.directory.uuid,
        f_name: "",
        f_numbers: [{ number: "", type: "Mobile" }],
      },
    })
  }

  redirectToFirstDirectory() {
    console.log("ContactsList.redirectToFirstDirectory")
    let user = JSON.parse(localStorage.getItem("user"))
    let directories = {}
    for (let directory of user.directories) {
      directories[directory.uuid] = directory
    }
    let directory = directories[Object.keys(directories)[0]]
    console.log(`Redirecting to ${directory.uuid}`)
    this.props.history.push({
      pathname: `/contacts/${directory.uuid}`,
    })
  }

  render() {
    let contactItems = this.state.contacts.map(contact => (
      <ContactItem
        key={contact.uuid}
        directoryUuid={this.state.directory.uuid}
        contactUuid={contact.uuid}
        name={contact.name}
        numbers={contact.phone_numbers}
      />
    ))
    let directories = Object.values(this.state.directories).map(directory => {
      let classNames = "directories-selection"
      if (directory.uuid === this.props.computedMatch.params.directoryUuid) {
        classNames += " active"
      }
      return (
        <div
          key={directory.uuid}
          className={classNames}
          onClick={this.handleSectionClick(`/contacts/${directory.uuid}`)}
        >
          <i className="fa fa-address-card" />
          {directory.name}
        </div>
      )
    })

    return (
      <div id="directory">
        <div id="slideout">
          <Sidebar selected="contacts" slideout={this.state.slideout} />
          <div id="directories-column">
            <Brand />
            <div className="directories-selections">
              <div className="directories-selections-header">Directories</div>
              {directories}
            </div>
          </div>
        </div>
        <div id="panel">
          <BetaBanner />
          <div className="content">
            <div className="directories-header">
              <i
                className="menu fa fa-bars"
                onClick={() => {
                  this.state.slideout.toggle()
                }}
              />
              <div className="directories-header-msg">
                <i className="fa fa-address-card" />
                {this.state.directory.name}
              </div>
              <div className="directories-header-fill" />
              <CircleButton iconClassName="fa-plus" className="circle-btn" onClick={this.handleNewContact} />
            </div>
            <div className="body">
              <div className="tbl">
                <div className="tbl-header">
                  <div className="tbl-row">
                    <div className="tbl-cell">Name</div>
                    <div className="tbl-cell">Phone Numbers</div>
                  </div>
                </div>
                <div className="tbl-body">{contactItems}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ContactsList)
