import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import Foreman from "./libraries/Foreman"

import Brand from "./components/Brand"
import ContactFormNumber from "./components/ContactFormNumber"
import FormInput from "./components/FormInput"
import FormSelect from "./components/FormSelect"
import HeaderButton from "./components/HeaderButton"
import Sidebar from "./components/Sidebar"
import Slideout from "slideout"

import "./css/Directory.css"
import "./ContactForm.css"
import "./slideout.css"

class ContactForm extends Component {
  constructor(props) {
    super(props)
    console.log("ContactForm.constructor")
    console.log(props)
    this.foreman = Foreman.getInstance()
    if (this.props.location.hasOwnProperty("state") && this.props.location.state) {
      console.log("found state")
      this.state = this.props.location.state
    } else {
      console.log("default state")
      this.state = {
        directories: [],
        f_uuid: this.props.uuid || "",
        f_directory: this.props.directory || "",
        f_name: this.props.name || "",
        f_numbers: this.props.numbers || [{ number: "", type: "Mobile" }],
        slideout: null,
      }
    }
  }

  componentWillMount() {
    console.log("ContactForm.componentWillMount")
    let user = JSON.parse(localStorage.getItem("user"))
    let directoryUuid = this.state.f_directory
    if (!directoryUuid) {
      directoryUuid = user.directories[0].uuid
    }
    this.setState({
      f_directory: directoryUuid,
      directories: user.directories,
    })
  }

  componentDidMount() {
    let slideout = new Slideout({
      panel: document.getElementById("panel"),
      menu: document.getElementById("slideout"),
      padding: 350,
      tolerance: 70,
    })
    this.setState({ slideout: slideout })
  }

  addNumber = () => {
    let f_numbers = this.state.f_numbers
    f_numbers.push({ number: "", type: "Work" })
    this.setState({ f_numbers: f_numbers })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleNumberChange = key => (number, type) => {
    console.log(`handleNumberChange(${key}, ${number}, ${type})`)
    let f_numbers = this.state.f_numbers
    f_numbers[key] = { number: number, type: type }
    this.setState({ f_numbers: f_numbers })
  }

  handleNumberDelete = key => () => {
    console.log(`handleNumberDelete(${key})`)
    let f_numbers = this.state.f_numbers
    delete f_numbers[key]
    this.setState({ f_numbers: f_numbers })
  }

  create = () => {
    console.log("ContactForm.create")
    let phone_numbers = this.state.f_numbers
      .filter(number => {
        return number != null
      })
      .map(number => {
        return {
          cc: 1,
          number: number.number,
          // type: number.type
        }
      })
    this.foreman.create_contact(this.state.f_directory, this.state.f_name, phone_numbers, (data, errs) => {
      if (errs) {
        console.log("Create failed )-:")
        console.log(errs)
      } else {
        console.log(`Create completed, redirecting to /contacts/${this.state.f_directory}`)
        this.props.history.push({
          pathname: `/contacts/${this.state.f_directory}`,
        })
      }
    })
  }

  update = () => {
    console.log("ContactForm.update")
    let phone_numbers = this.state.f_numbers
      .filter(number => {
        return number != null
      })
      .map(number => {
        return {
          cc: 1,
          number: number.number,
          // type: number.type
        }
      })
    this.foreman.update_contact(
      this.state.f_directory,
      this.state.f_uuid,
      this.state.f_name,
      phone_numbers,
      (data, errs) => {
        if (errs) {
          console.log("Update failed )-:")
          console.log(errs)
        } else {
          console.log("Update completed!")
          this.props.history.push({
            pathname: `/contacts/${this.state.f_directory}`,
          })
        }
      },
    )
  }

  save = event => {
    let action = "Create"
    if (this.state.f_uuid) {
      action = "Update"
    }
    console.group(`${action} contact!`)
    console.log(`UUID: ${this.state.f_uuid}`)
    console.log(`Directory: ${this.state.f_directory}`)
    console.log(`Name: ${this.state.f_name}`)
    this.state.f_numbers.forEach((number, idx) => {
      console.log(`Number(${idx}): cc: 1, number: ${number.number}, type: ${number.type}`)
    })
    console.groupEnd()
    if (this.state.f_uuid) {
      this.update()
    } else {
      this.create()
    }
  }

  handleSectionClick = pathname => event => {
    this.props.history.push({
      pathname: pathname,
    })
    this.state.slideout.close()
  }

  render() {
    let directoryOptions = this.state.directories.map(directory => {
      return { name: directory.name, value: directory.uuid }
    })
    let headerText = "New Contact"
    let directoryDisabled = false
    if (this.state.f_uuid) {
      headerText = "Edit Contact"
      directoryDisabled = true
    }
    let directories = Object.values(this.state.directories).map(directory => {
      let classNames = "directories-selection"
      if (directory.uuid === this.state.f_directory) {
        classNames += " active"
      }
      return (
        <div className={classNames} onClick={this.handleSectionClick(`/contacts/${directory.uuid}`)}>
          <i className="fa fa-address-card" />
          {directory.name}
        </div>
      )
    })

    return (
      <div id="directory">
        <div id="slideout">
          <Sidebar selected="contacts" slideout={this.state.slideout} />
          <div id="directories-column">
            <Brand />
            <div className="directories-selections">
              <div className="directories-selections-header">Directories</div>
              {directories}
            </div>
          </div>
        </div>
        <div id="panel">
          <div className="content">
            <div className="directories-header">
              <i
                className="menu fa fa-bars"
                onClick={() => {
                  this.state.slideout.toggle()
                }}
              />
              <div className="directories-header-msg">
                <i className="fa fa-address-card" />
                {headerText}
              </div>
            </div>
            <div className="body">
              <div className="row">
                <FormSelect
                  name="f_directory"
                  info="Directory"
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12, col-xl-12"
                  value={this.state.f_directory}
                  disabled={directoryDisabled}
                  options={directoryOptions}
                  onChange={this.handleInputChange}
                />
                <FormInput
                  name="f_name"
                  info="Name"
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12, col-xl-12"
                  value={this.state.f_name}
                  onChange={this.handleInputChange}
                />
                {this.state.f_numbers.map((number, idx) => (
                  <ContactFormNumber
                    key={idx}
                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12, col-xl-12"
                    handleNumberChange={this.handleNumberChange(idx)}
                    handleNumberDelete={this.handleNumberDelete(idx)}
                    number={number.number}
                    type={number.type}
                  />
                ))}
                <div className="row contact-form-row-btns">
                  <HeaderButton icon="fa-plus" text="Add Number" className="btn-white" onClick={this.addNumber} />
                  <HeaderButton icon="fa-floppy-o" text="Save" onClick={this.save} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ContactForm.propTypes = {}

export default withRouter(ContactForm)
