import React, { Component } from "react"
import PropTypes from "prop-types"
import MessageHeader from "./MessageHeader"
import MessageItem from "./MessageItem"
import "./Messages.css"

class Messages extends Component {
  constructor(props) {
    super(props)
    this.handleTextareaKeyPress = this.handleTextareaKeyPress.bind(this)
    this.handleAirplaneClick = this.handleAirplaneClick.bind(this)
    this.state = {
      timestamps: true,
    }
  }

  scrollToBottom = behavior => {
    let child = this.msgsBox.querySelector(".msg-box:last-child")
    if (child) {
      child.scrollIntoView({ behavior: behavior })
    }
  }

  componentDidMount() {
    this.scrollToBottom("instant")
  }

  componentDidUpdate() {
    this.scrollToBottom("instant")
  }

  handleAirplaneClick(event) {
    let messagesParams = this.props.messagesParams
    let msg = this.messageWindow.value.trim()
    this.props.sendMessage(messagesParams.roomUuid, messagesParams.conversationUuid, msg)
    this.messageWindow.value = ""
    this.resizeMessageWindow()
  }

  handleTextareaKeyPress(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      let messagesParams = this.props.messagesParams
      let msg = event.target.value.trim()
      this.props.sendMessage(messagesParams.roomUuid, messagesParams.conversationUuid, msg)
      event.target.value = ""
    }
    this.resizeMessageWindow()
  }

  resizeMessageWindow() {
    this.messageWindow.style.height = "3rem"
    this.messageWindow.style.height = this.messageWindow.scrollHeight + "px"
  }

  compareMessageTimestamp(a, b) {
    if (a.received.valueOf() < b.received.valueOf()) {
      return -1
    }
    if (a.received.valueOf() > b.received.valueOf()) {
      return 1
    }
    return 0
  }

  render() {
    let messagesParams = this.props.messagesParams
    let rooms = this.props.rooms
    let room = null
    let conversation = null
    let messageItems = null
    let signature = <span className="signature" />
    if (rooms.hasOwnProperty(messagesParams.roomUuid)) {
      room = rooms[messagesParams.roomUuid]
      if (room && room.conversations.hasOwnProperty(messagesParams.conversationUuid)) {
        conversation = room.conversations[messagesParams.conversationUuid]
        let msgs = Object.values(conversation.messages).sort(this.compareMessageTimestamp)
        messageItems = msgs.map(message => (
          <MessageItem key={message.uuid} message={message} timestamps={this.state.timestamps} />
        ))
        if (room.useSignature) {
          signature = <span className="signature">Signature: -{this.props.user.signature}</span>
        }
      }
    }
    let footer = null
    if (!messagesParams.archived) {
      footer = (
        <div
          className="footer"
          ref={el => {
            this.footer = el
          }}
        >
          <div className="message-window">
            <textarea
              placeholder="Write a message"
              onKeyUp={this.handleTextareaKeyPress}
              ref={el => {
                this.messageWindow = el
              }}
            />
            {signature}
            <div className="send">
              <i className="fa fa-fw fa-paper-plane" onClick={this.handleAirplaneClick} />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div id="messages">
        <div className="conversation">
          <MessageHeader room={room} conversation={conversation} slideout={this.props.slideout} />
          <div
            className="messages-box"
            ref={el => {
              this.messagesBox = el
            }}
          >
            <div
              className="msgs-box"
              ref={el => {
                this.msgsBox = el
              }}
            >
              {messageItems}
            </div>
          </div>
          {footer}
        </div>
      </div>
    )
  }
}

Messages.propTypes = {
  messagesParams: PropTypes.object.isRequired,
  user: PropTypes.object,
  rooms: PropTypes.object,
  sendMessage: PropTypes.func.isRequired,
}

export default Messages
