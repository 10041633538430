import React, { Component } from "react"
import PropTypes from "prop-types"
import NewMessageHeader from "./NewMessageHeader"
import "./Messages.css"

class NewMessage extends Component {
  render() {
    return (
      <div id="messages">
        <div className="conversation">
          <NewMessageHeader
            messagesParams={this.props.messagesParams}
            rooms={this.props.rooms}
            sendCreateConversation={this.props.sendCreateConversation}
            slideout={this.props.slideout}
          />
          <div className="messages">
            <div className="msgs-box" />
          </div>
          <div className="footer" />
        </div>
      </div>
    )
  }
}

NewMessage.propTypes = {
  messagesParams: PropTypes.object.isRequired,
  rooms: PropTypes.object.isRequired,
  sendCreateConversation: PropTypes.func.isRequired,
}

export default NewMessage
