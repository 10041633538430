import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import LogRocket from "logrocket"

import Foreman from "../libraries/Foreman"

import "./Sidebar.css"

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.foreman = Foreman.getInstance()
    this.selectConversation = this.selectConversation.bind(this)
  }

  selectConversation(uuid) {
    this.props.selectConversation(uuid)
  }

  handleSectionClick = selected => event => {
    console.log("handleSectionClick")
    if (this.props.selected !== selected) {
      console.log(`Redirecting to ${selected}`)
      this.props.history.push({
        pathname: `/${selected}`,
      })
    }
    this.props.slideout.close()
  }

  handleLogout = event => {
    console.log("Sidebar.handleLogout")
    this.foreman.logout()
    LogRocket.startNewSession()
  }

  render() {
    let messagesClasses = "sidebar-item"
    let contactsClasses = "sidebar-item"
    let settingsClasses = "sidebar-item"

    switch (this.props.selected) {
      case "messages":
        messagesClasses += " sidebar-item-active"
        break
      case "contacts":
        contactsClasses += " sidebar-item-active"
        break
      case "settings":
        settingsClasses += " sidebar-item-active"
        break
      default:
        console.log("No selected section")
    }

    return (
      <div id="sidebar">
        <div className={messagesClasses}>
          <div className="btn-sidebar-item" onClick={this.handleSectionClick("messages")}>
            <i className="fa fa-comments" />
          </div>
          <p className="sidebar-name">Messages</p>
        </div>

        <div className={contactsClasses}>
          <div className="btn-sidebar-item" onClick={this.handleSectionClick("contacts")}>
            <i className="fa fa-address-card" />
          </div>
          <p className="sidebar-name">Contacts</p>
        </div>

        <div className={settingsClasses}>
          <div className="btn-sidebar-item" onClick={this.handleSectionClick("settings")}>
            <i className="fa fa-cog" />
          </div>
          <p className="sidebar-name">Settings</p>
        </div>

        <div className="fill" />

        <div className="sidebar-item sidebar-footer">
          <div className="btn-sidebar-item" onClick={this.handleLogout}>
            <i className="fa fa-sign-out" />
          </div>
          <p className="sidebar-name">Logout</p>
        </div>
      </div>
    )
  }
}

export default withRouter(Sidebar)
