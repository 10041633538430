import PropTypes from "prop-types"
import React, { Component } from "react"
import Foreman from "../libraries/Foreman"
import "./SearchItems.css"

class SearchItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      message: "",
    }
    this.foreman = Foreman.getInstance()
    this.selectItem = this.selectItem.bind(this)
  }

  searchContactsForMatch() {
    const query = this.props.query
    console.log(`searchContactsForMatch(query=${query})`)
    const isName = isNaN(query)

    this.foreman.search_contact(query, data => {
      console.log("search returns")
      const items = data.map((contact, index) => (
        <tr className="search-item" key={index} onClick={() => this.selectItem(contact.resource)}>
          <td className="search-item-primary">
            <span>{isName ? contact.name : contact.resource}</span>
          </td>
          <td className="search-item-secondary">
            <div>{isName ? contact.resource : contact.name}</div>
            <div>{contact.directory}</div>
          </td>
        </tr>
      ))
      let message = ""
      if (!data.length) {
        message = "No results found"
      } else if (data.length > 10) {
        message = "Please be more specific"
      }
      this.setState({ items, message }, () => {
        this.props.searchItemChange(items, message)
      })
    })
  }

  selectItem(number) {
    this.props.chooseNumber(number)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query) {
      if (this.isOverMinQuery() && !this.isOverMaxQuery()) {
        this.searchContactsForMatch()
      } else {
        this.setState({ items: [], message: "" }, () => {
          this.props.searchItemChange([], "")
        })
      }
    }
  }

  hasNoItems() {
    return !this.state.items || this.state.items < 1
  }
  isOverMinQuery() {
    return this.props.query && this.props.query.length >= this.props.minQuerySize
  }
  isOverMaxQuery() {
    return this.props.query && !isNaN(this.props.query) && this.props.query.length >= 10
  }
  hasNoMessage() {
    return !this.state.message
  }

  render() {
    if (this.hasNoMessage() && (this.hasNoItems() || this.isOverMaxQuery())) {
      return null
    }
    return this.state.message ? (
      <div className="search-message">{this.state.message}</div>
    ) : (
      <table className="search-table">
        <tbody>{this.state.items}</tbody>
      </table>
    )
  }
}

SearchItems.propTypes = {
  minQuerySize: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired,
  chooseNumber: PropTypes.func.isRequired,
  searchItemChange: PropTypes.func.isRequired,
}

export default SearchItems
