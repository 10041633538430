import React, { Component } from "react"
import "./ErrorItem.css"

class ErrorItem extends Component {
  constructor(props) {
    super(props)
    this.setUserAuthenticated = this.props.setUserAuthenticated
  }

  render() {
    return (
      <div className="error-message">
        <i className="fa fa-exclamation-triangle" /> {this.props.error}
      </div>
    )
  }
}

export default ErrorItem
