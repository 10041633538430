import "../node_modules/font-awesome/css/font-awesome.min.css"
import "./index.css"
import "flexboxgrid2"

import LogRocket from "logrocket"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom"

import LinkWebex from "./app/components/LinkWebex"
import WebexUser from "./app/components/WebexUser"
import ContactDetail from "./app/ContactDetail"
import ContactForm from "./app/ContactForm"
import ContactsList from "./app/ContactsList"
import Foreman from "./app/libraries/Foreman"
import MessagesApp from "./app/MessagesApp"
import SettingsApp from "./app/SettingsApp"
import Auth from "./auth/Auth"
import { unregister } from "./registerServiceWorker"

class PrivateRoute extends React.Component {
  render() {
    const Component = this.props.component
    if (this.props.isAuthenticated === true) {
      return <Component {...this.props} />
    } else {
      sessionStorage.setItem("referrer", this.props.location.pathname)
      return <Redirect to={{ pathname: "/auth", search: this.props.location.search }} />
    }
  }
}

class BaseLayout extends React.Component {
  constructor(props) {
    super(props)
    this.foreman = Foreman.getInstance()
    this.state = {
      isAuthenticated: this.foreman.is_authenticated(),
    }
    this.authenticate = this.authenticate.bind(this)
    this.signout = this.signout.bind(this)
  }

  componentWillMount() {
    window.addEventListener("focus", event => {
      window.hasfocus = true
    })
    window.addEventListener("blur", event => {
      window.hasfocus = false
    })
    if (this.foreman.is_authenticated()) {
      this.foreman.get_current_user((user, errs) => {
        if (errs) {
          console.log("Error setting Identity")
        } else {
          console.log("Identity Set")
          LogRocket.identify(user.uuid, {
            name: user.name,
            email: user.email,
            provider: user.provider,
            account: user.account,
          })
        }
      })
    }
  }

  authenticate(email, password, cb) {
    console.log("BaseLayout.authenticate")
    this.foreman.login(email, password, (success, errs) => {
      if (success) {
        this.foreman.get_current_user((user, errs) => {
          console.log("Identity Set")
          LogRocket.identify(user.uuid, {
            name: user.name,
            email: user.email,
            provider: user.provider,
            account: user.account,
          })
        })
      }
      this.setState(() => ({
        isAuthenticated: success,
      }))
      cb(success, errs)
    })
  }

  signout() {
    console.log("BaseLayout.signout")
    this.setState(() => ({
      isAuthenticated: false,
    }))
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/auth"
            render={props => (
              <Auth {...props} authenticate={this.authenticate} isAuthenticated={this.state.isAuthenticated} />
            )}
          />
          <PrivateRoute isAuthenticated={this.state.isAuthenticated} component={LinkWebex} path="/link-webex" />
          <PrivateRoute isAuthenticated={this.state.isAuthenticated} component={WebexUser} path="/webex-user" />
          <PrivateRoute isAuthenticated={this.state.isAuthenticated} component={ContactForm} path="/contacts/new" />
          <PrivateRoute
            isAuthenticated={this.state.isAuthenticated}
            component={ContactForm}
            path="/contacts/:directoryUuid/:contactUuid/edit"
          />
          <PrivateRoute
            isAuthenticated={this.state.isAuthenticated}
            component={ContactDetail}
            path="/contacts/:directoryUuid/:contactUuid"
          />
          <PrivateRoute
            isAuthenticated={this.state.isAuthenticated}
            component={ContactsList}
            path="/contacts/:directoryUuid?"
          />
          <PrivateRoute
            isAuthenticated={this.state.isAuthenticated}
            component={MessagesApp}
            path="/messages/:roomUuid?/:conversationUuid?"
          />
          <PrivateRoute
            isAuthenticated={this.state.isAuthenticated}
            component={SettingsApp}
            path="/settings/:section?"
          />
          <PrivateRoute isAuthenticated={this.state.isAuthenticated} component={MessagesApp} path="/" />
        </Switch>
      </Router>
    )
  }
}

export default BaseLayout

if (process.env.NODE_ENV.startsWith("production")) {
  LogRocket.init("aphp5b/crew")
} else {
  console.info(`NODE_ENV: ${process.env.NODE_ENV}`)
  console.info(`REACT_APP_FOREMAN_API_URI: ${process.env.REACT_APP_FOREMAN_API_URI}`)
  console.info(`REACT_APP_FOREMAN_NOTIFY_URI: ${process.env.REACT_APP_FOREMAN_NOTIFY_URI}`)
  console.info(`REACT_APP_FOREMAN_NOTIFY_PATH: ${process.env.REACT_APP_FOREMAN_NOTIFY_PATH}`)
}
ReactDOM.render(<BaseLayout />, document.getElementById("root"))
// unregister service workers
unregister()
