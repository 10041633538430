import React, { Component } from "react"

import Alert from "./Alert"
import FormInput from "./FormInput"
import HeaderButton from "./HeaderButton"
import Foreman from "../libraries/Foreman"
import "./SettingsSecurity.css"

class SettingsSecurity extends Component {
  constructor(props) {
    super(props)
    this.foreman = Foreman.getInstance()
    this.state = {
      f_password: "",
      f_new_password: "",
      processing: false,
      btn_classes: "fa-floppy-o",
      messages: [],
    }
  }

  componentWillMount() {
    console.log("SettingsProfile.componentWillMount")
    this.foreman.get_current_user(user => {
      this.setState({ f_uuid: user.uuid })
    })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  save = event => {
    console.group(`Update Password for ${this.state.f_uuid}!`)
    console.log(`UUID: ${this.state.f_uuid}`)
    console.groupEnd()
    // set processing
    this.setState({
      processing: true,
      btn_classes: "fa-spinner fa-pulse",
      messages: [],
    })
    this.foreman.update_current_user_password(this.state.f_password, this.state.f_new_password, (success, errs) => {
      if (errs) {
        console.log("Update Current User Password failed )-:")
        console.log(errs)
        // end processing
        setTimeout(() => {
          this.setState({
            processing: false,
            btn_classes: "fa-floppy-o",
            messages: errs.map((error, idx) => (
              <Alert key={idx} className="error" icon="fa-exclamation-triangle" message={error} />
            )),
          })
        }, 500)
      } else {
        console.log("Update Current User Password completed!")
        // end processing
        setTimeout(() => {
          this.setState({
            processing: false,
            btn_classes: "fa-floppy-o",
            errors: [],
            f_password: "",
            f_new_password: "",
            messages: [<Alert className="success" icon="fa-check-circle" message="Updated Password!" />],
          })
        }, 500)
      }
    })
  }

  render() {
    return (
      <div className="settings-form">
        <div className="messages">{this.state.messages}</div>
        <FormInput
          name="f_password"
          type="password"
          info="Current Password"
          value={this.state.f_password}
          onChange={this.handleInputChange}
        />
        <FormInput
          name="f_new_password"
          type="password"
          info="New Password"
          value={this.state.f_new_password}
          onChange={this.handleInputChange}
        />
        <div className="settings-form-actons">
          <HeaderButton
            className="settings-form-save"
            icon={this.state.btn_classes}
            text="Save"
            disabled={this.state.processing}
            onClick={this.save}
          />
        </div>
      </div>
    )
  }
}

export default SettingsSecurity
