import PropTypes from "prop-types"
import React, { Component } from "react"
import HeaderButton from "./HeaderButton"
import HeaderInput from "./HeaderInput"
import HeaderSelect from "./HeaderSelect"
import "./MessageHeader.css"

class NewMessageHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      roomUuid: this.props.messagesParams.roomUuid,
      resource: "",
      valid: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleResourceKeyPress = this.handleResourceKeyPress.bind(this)
    this.isResourceValid = this.isResourceValid.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  parseResource(data) {
    data = data.replace(/[^\d\\-\\+]/g, "")
    var regex = /^(\+)?(1)?([2-9]\d{2}[2-9]\d{2}\d{4})$/
    var match = regex.exec(data)
    if (match) {
      return `+1-${match[3]}`
    } else {
      return null
    }
  }

  isResourceValid() {
    if (this.parseResource(this.state.resource.trim()) !== null) {
      return true
    } else {
      return false
    }
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
      valid: this.isResourceValid(),
    })
  }

  handleConfirm(event) {
    var roomUuid = this.state.roomUuid.trim()
    var resource = this.state.resource.trim()
    resource = this.parseResource(resource)
    if (roomUuid !== "" && resource != null) {
      console.log(`New Conversation in room ${roomUuid} to ${resource}`)
      this.props.sendCreateConversation(roomUuid, resource)
    }
  }

  handleResourceKeyPress(event) {
    this.handleInputChange(event)
    if (event.key === "Enter" && event.target.name === "resource") {
      this.handleConfirm(event)
      return false
    }
  }

  render() {
    let rooms = this.props.rooms
    let roomOptions = Object.keys(rooms).map(function(key, idx) {
      return {
        name: rooms[key].name,
        value: key,
      }
    })
    let btnClass = "new-message-begin-btn col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2"
    if (!this.state.valid) {
      btnClass += " disabled"
    }
    return (
      <div className="header header-new row row-nomargin">
        <div className="col-xs-12">
          <i
            className="menu fa fa-bars"
            onClick={() => {
              this.props.slideout.toggle()
            }}
          />
          <span className="hidden-sm hidden-md hidden-lg hidden-xl">New Message</span>
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <HeaderSelect
            name="roomUuid"
            value={this.state.roomUuid}
            options={roomOptions}
            info="Room List"
            onChange={this.handleInputChange}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <HeaderInput
            name="resource"
            info="Search for Contacts or 10-Digit Number"
            type="tel"
            onChange={this.handleInputChange}
            onKeyUp={this.handleResourceKeyPress}
          />
        </div>
        <HeaderButton text="Begin" icon="fa-arrow-circle-down" onClick={this.handleConfirm} className={btnClass} />
      </div>
    )
  }
}

NewMessageHeader.propTypes = {
  rooms: PropTypes.object.isRequired,
  messagesParams: PropTypes.object.isRequired,
  sendCreateConversation: PropTypes.func.isRequired,
}

export default NewMessageHeader
